import React from "react";
import styled from "styled-components";
import IndividualSection from "./IndividualSection.jsx";
import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import { useDispatch, useSelector } from "react-redux";
import { setMetadata } from "../Document/documentSlice.js";


const SectionList = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isDraggingOver"].includes(prop),
})`
  width: 125ch;
  border-radius: 8px;
  background-color: ${(props) =>
    props.isDraggingOver ? "rgba(209, 234, 255, .65)" : "rgba(0,0,0,0)"};
  transition: background-color .33s ease-in-out;
`;

function Sections(
  {
    staffElements,
    setStaffElements,
    setSectionExpanded,
    sectionExpanded,
    dialogue,
    page,
    setDialogue,
    characterFocus,
    setCharacterFocus,
    handleShowSectionModal,
  },
) {
  function handleExpandSection(sectionName) {
    setCharacterFocus("");
    {
      sectionExpanded.includes(sectionName)
        ? setSectionExpanded(sectionExpanded.filter((x) => x !== sectionName))
        : setSectionExpanded([...sectionExpanded, sectionName]);
    }
  }
  const dispatch = useDispatch();
  const metadata = useSelector((state) => state.document.metadata);
  const sectionOrderArray = metadata.sectionOrderArray === undefined
    ? {}
    : metadata.sectionOrderArray;

  function getSectionOrder(sectionOrderArray, page, staffElements) {
    if (Object.keys(sectionOrderArray).length === 0) {
      const defaultValue = staffElements.sections.map((s) => s.sectionName);
      return defaultValue;
    }
    const sectionOrderArrayKeys = Object.keys(sectionOrderArray).map((k) =>
      parseInt(k)
    );
    const index = sectionOrderArrayKeys.reduce(
      (accum, curr) => (curr > accum && curr <= page) ? curr : accum,
      0,
    );

    if (index === 0) {
      return [];
    }
    return sectionOrderArray[index];
  }

  const sectionOrder = getSectionOrder(sectionOrderArray, page, staffElements);
  const sectionDisplay = [
    ...sectionOrder,
    ...staffElements.sections.map((s) => s.sectionName).filter((s) =>
      !sectionOrder.includes(s)
    ),
  ];
  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }
    if (destination.droppableId !== source.droppableId) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    if (destination.droppableId === source.droppableId) {
      const newSectionOrder = Array.from(
        sectionOrder.toSpliced(source.index, 1).toSpliced(
          destination.index,
          0,
          draggableId,
        ),
      );
      let nextSectionOrderArray = { ...sectionOrderArray };
      nextSectionOrderArray[page] = newSectionOrder;
      dispatch(
        setMetadata({
          metadata: { ...metadata, sectionOrderArray: nextSectionOrderArray },
        }),
      );
    }
  };

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
    >
      <Droppable droppableId={page.toString()}>
        {(provided, snapshot) => (
          <SectionList
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {sectionDisplay.map((s, index) => {
              const section = staffElements.sections.find((sec) =>
                sec.sectionName === s
              );
              if (section === undefined) {
                return null;
              } else {
                return (
                  <IndividualSection
                    key={section.sectionName}
                    index={index}
                    section={section}
                    page={page}
                    handleExpandSection={handleExpandSection}
                    staffElements={staffElements}
                    setStaffElements={setStaffElements}
                    dialogue={dialogue}
                    setDialogue={setDialogue}
                    sectionExpanded={sectionExpanded}
                    setSectionExpanded={setSectionExpanded}
                    characterFocus={characterFocus}
                    setCharacterFocus={setCharacterFocus}
                    handleShowSectionModal={handleShowSectionModal}
                  />
                );
              }
            })}
            {provided.placeholder}
          </SectionList>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default Sections;
