import React from "react";
import StaffName from "./StaffName";
import DialogueInput from "./DialogueInput";
import Transitions from "./Transitions";
import { Draggable } from "@hello-pangea/dnd";
import styled from "styled-components";

const StaffContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isDragging'].includes(prop),
})`
  background: ${(props) =>
    props.isDragging ? "rgba(255,255,255,0.75)" : "transparent"};
  box-shadow: ${(props) =>
    props.isDragging ? "2px 2px 3px rgba(0,0,0,0.25)" : "none"};
  border-radius: 8px;
  display: grid;
  grid-template-columns: 12ch 4px auto;
  transition: margin-top ease-in 0.5s;
  margin-top: 0rem;
  margin: .25rem 0px;
  min-width: 125ch;
  max-width: fit-content;
  font-family: "Courier New", Courier, monospace;
  font-size: inherit;
  letter-spacing: inherit;
`;

export default function StaffSectionElementExpanded({
  element,
  highlighted,
  rowStyle,
  dialogue,
  setDialogue,
  section,
  page,
  staffElements,
  setStaffElements,
  characterFocus,
  setCharacterFocus,
  index,
  isSectionExpanded
}) {
  const sectionElementTransitions =
    staffElements.sections.find((sec) =>
      sec.sectionName === section.sectionName
    ).elements.find((elem) => elem.elementName === element)["transitions"];

  const [stageDirections, setStageDirections] = React.useState(
    sectionElementTransitions === undefined ? [] : sectionElementTransitions,
  );
  const [showStageDirections, setShowStageDirections] = React.useState(false);

  const handleCloseStageDirections = () => {
    
    // Get section element
    const sectionElement = staffElements.sections.find((sec) =>
      sec.sectionName === section.sectionName
    ).elements.find((elem) => elem.elementName === element);

    const newSectionElement = {
      ...sectionElement,
      transitions: stageDirections,
    };

    // Check if a change has been made to avoid a rerender
    if (JSON.stringify(newSectionElement) !== JSON.stringify(sectionElement)) {
      const nextSection = {
        ...section,
        elements: section.elements.map((sE) =>
          sE.elementName === element ? newSectionElement : sE
        ),
      };
      // Insert it into the proper place in the array
      let nextStaffElements = {
        ...staffElements,
        sections: staffElements.sections.map((sec) =>
          sec.sectionName === section.sectionName ? nextSection : sec
        ),
      };
      setStaffElements(nextStaffElements);
    }
    setShowStageDirections(false);
  };
  const menuOptionsSection = [{
    label: "Transitions",
    action: () => {
      return setShowStageDirections(true);
    },
  }];
  return (
    <Draggable draggableId={element} index={index} isDragDisabled={!isSectionExpanded}>
      {(provided, snapshot) => (
        <StaffContainer
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
        >
          <StaffName
            elementName={element}
            menuOptions={menuOptionsSection}
            highlighted={false}
            isSLine={false}
          />
          {showStageDirections
            ? (
              <Transitions
                handleCloseStageDirections={handleCloseStageDirections}
                staffElements={staffElements}
                setStaffElements={setStaffElements}
                stageDirections={stageDirections}
                setStageDirections={setStageDirections}
                setShowStageDirections={setShowStageDirections}
                showStageDirections={showStageDirections}
                highlighted={highlighted}
                rowStyle={rowStyle}
                element={element}
                dialogue={dialogue}
                section={section}
                page={page}
              />
            )
            : (
              <DialogueInput
                stageDirections={stageDirections}
                rowStyle={"staff-row"}
                element={element}
                dialogue={dialogue}
                section={section.sectionName}
                characterFocus={characterFocus}
                setCharacterFocus={setCharacterFocus}
                setDialogue={setDialogue}
                page={page}
              />
            )}
        </StaffContainer>
      )}
    </Draggable>
  );
}
