import React from "react";
import { Button, Typography } from "@mui/joy";
import { getElementsInSection } from "../Common/Utils/ElementsAndSectionUtils";
import SectionElementExpanded from "./SectionElementExpanded";
import SectionHeaderButton from "../Common/SectionHeaderButton";
function PresentationSections(
  {
    setSectionExpanded,
    sectionExpanded,
    sections,
    dialogue,
    page,
    selectedElement,
  },
) {
  const handleExpandSection = (sectionName) => {
    sectionExpanded.includes(sectionName)
      ? setSectionExpanded(
        sectionExpanded.filter((x) => x !== sectionName),
      )
      : setSectionExpanded([
        ...sectionExpanded,
        sectionName,
      ]);
  };

  return (
    <>
      {/* For each section render a section name and sectior row that can be expanded */}
      {sections.map((section, index) => {
        const elementsInSection = getElementsInSection(page, section);
        const isSectionExpanded = sectionExpanded.includes(section.sectionName);
        const sLine = section.elements[0];
        const sLineHighlighted = sLine.elementName === selectedElement;
        return (
          <div
            key={section.sectionName}
          >
            {selectedElement === null ||
              elementsInSection.includes(selectedElement)
              ? (
                <div>
                  <div className="staff-container">
                    <SectionHeaderButton
                      sectionName={section.sectionName}
                      onClickAction={() =>
                        handleExpandSection(section.sectionName)}
                      isSectionExpanded={isSectionExpanded}
                    />

                    {elementsInSection.length > 0
                      ? (
                        <>
                          <SectionElementExpanded
                            element={section.elements[0].elementName}
                            highlighted={sLineHighlighted}
                            rowStyle={"staff-row-presentation"}
                            dialogue={dialogue}
                            section={section}
                            page={page}
                            emptySpacer={false}
                          />
                        </>
                      )
                      : (
                        <div
                          style={{
                            display: "grid",
                            gridColumn: "2 / 5",
                            padding: "0.5rem 0",
                            borderRadius: "8px",
                            alignContent: "center",
                            justifyContent: "center",
                            backgroundColor: "rgb(236, 236, 236)",
                          }}
                        >
                          <Typography level="h4">
                            Section contains no elements
                          </Typography>
                        </div>
                      )}
                    {/* if sectionExpeded === true and selection has more than one element:  add a spacer */}
                  </div>
                  {isSectionExpanded
                    ? elementsInSection.map((element, index) => {
                      if (index > 0) {
                        return (
                          <SectionElementExpanded
                            key={element}
                            element={element}
                            highlighted={selectedElement === element}
                            rowStyle={"staff-row-presentation"}
                            dialogue={dialogue}
                            section={section}
                            page={page}
                            emptySpacer={index ===
                              elementsInSection.length - 1}
                          />
                        );
                      }
                    })
                    : (null)}
                </div>
              )
              : (null)}
          </div>
        );
      })}
    </>
  );
}

export default PresentationSections;
