import React from "react";
import Swseng from "../../static/images/swseng4.png";
import { Link as RouterLink } from "react-router-dom";
import PillButtonLink from "../Common/PillButtonLink";
import axios from "axios";
import SwsengLogo from "../../static/images/SWSENG_Logo_Cropped.png";
import { logoutUser, setUserData } from "../User/userSlice.js";
import OrangeLogo from "../../static/images/OrangeBar_Cropped.png";
import RedLogo from "../../static/images/PinkBar_Cropped.png";
import BlueLogo from "../../static/images/BlueBar_Cropped.png";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Container,
  createTheme,
  CssBaseline,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import EditNoteIcon from "@mui/icons-material/EditNote";
import LogoutIcon from "@mui/icons-material/Logout";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import AliceRegular from "../../static/fonts/Alice-Regular.ttf";
import LibreFranklinRegular from "../../static/fonts/LibreFranklin-Regular.ttf";
import CTAButton from "../Common/CTAButton.jsx";
const swsengPallet = { yellow: "#fdc65d", blue: "#75d3e5", pink: "#f395a9" };
const theme = createTheme({
  typography: {
    h1: {
      fontFamily: "Alice",
      fontSize: "5.0rem",
      lineHeight: "0.80",
      letterSpacing: "-0.30rem",
      fontWeight: "500",
      color: "#363635",
    },
    h2: {
      fontFamily: "Alice",
      letterSpacing: "-0.20rem",
    },
    h3: {
      fontFamily: "Alice",
      lineHeight: "0.85",
      letterSpacing: "-0.13rem",
      textAlign: "center",
    },
    h4: {
      fontFamily: "Alice",
      letterSpacing: "-0.13rem",
    },
    h5: {
      fontFamily: "Alice",
      letterSpacing: "-0.1rem",
    },
    p: {
      fontFamily: "LibreFranklin",
    },
    p1: {
      fontFamily: "LibreFranklin",
      lineHeight: "1.125",
      letterSpacing: "0px",
      // letterSpacing: "0.0425rem",
      fontSize: "1.25rem",
      textWrap: "balance",
      margin: "0 0",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Alice';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Alice'), local('Alice-Regular'), url(${AliceRegular}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'LibreFranklin';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('LibreFranklin'), local('LibreFranklin-Regular'), url(${LibreFranklinRegular}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const HoveringNeonElement = ({ buttonLabel }) => {
  return (
    <Box
      sx={{
        background:
          `linear-gradient(-45deg, #7fdaff, #ffa63d, #ff3d77, #ffa63d)`,
        backgroundSize: "500%",
        animation: `gradient 10s linear infinite`,
        position: "absolute",
        marginTop: ".5rem",
        zIndex: "-1",
        height: "1.75rem",
        filter: "blur(.5rem)",
        opacity: "1",
        // width: "8ch",
      }}
    >
      {buttonLabel}
    </Box>
  );
};
// const black = {
//   50: "#999",
//   100: "#888",
//   200: "#777",
//   300: "#666",
//   400: "#555",
//   500: "#444",
//   600: "#333",
//   700: "#222",
//   800: "#111",
//   900: "#000",
// };
//
// const blue = {
//   200: "#99CCFF",
//   300: "#66B2FF",
//   400: "#3399FF",
//   500: "#007FFF",
//   600: "#0072E5",
//   700: "#0066CC",
// };
//
// const grey = {
//   50: "#F3F6F9",
//   100: "#E5EAF2",
//   200: "#DAE2ED",
//   300: "#C7D0DD",
//   400: "#B0B8C4",
//   500: "#9DA8B7",
//   600: "#6B7A90",
//   700: "#434D5B",
//   800: "#303740",
//   900: "#1C2025",
// };

function Landing() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const apiKey = process.env.REACT_APP_API_KEY;
  const localToken = localStorage.getItem("Swseng_token");
  React.useEffect(() => {
    async function getUser() {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Token ${localToken}`,
          "Swseng-Api-Key": apiKey,
        },
      };
      try {
        const response = await axios.get(
          apiEndpoint + "/user/",
          config,
        );
        dispatch(setUserData(
          { user: { ...response.data }, token: localToken },
        ));
      } catch (error) {
        console.error(error);
      }
    }

    if (localToken) {
      getUser();
    }
  }, []);
  const featureCardContent = [
    {
      title: "Expansive Composition",
      text:
        "Horizontal layout that allows you to visualize the entire stage and orchestrate events across multiple spaces.",
      color: swsengPallet.pink,
    },
    {
      title: "Overlapping Dialogue",
      text:
        "Easily layered dialogue that creates a physical and sonic performance as specific or freeform as you like.",
      color: swsengPallet.yellow,
    },
    {
      title: "Emergent Rhythms",
      text:
        "Cues that are found through the rhythm of natural language, not overwrought stage directions.",
      color: swsengPallet.blue,
    },
  ];
  const FeatureCard = () => {
    return (
      <>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 2, sm: 4, md: 6, lg: 10 }}
          useFlexGap
          justifyContent={"center"}
          display="flex"
          gap={"0.5rem"}
        >
          {featureCardContent.map((fC) => {
            return (
              <Box
                key={fC.title}
                maxWidth={{ xs: "100%", sm: "17%" }}
                minWidth={{ xs: "100%", sm: "calc(30% - 14px)" }}
              >
                <Typography
                  variant="h5"
                  maxWidth={{ xs: "100%", sm: "10ch" }}
                  // textAlign={"center"}
                  sx={{
                    fontSize: "1.75rem",
                    mb: 3,
                  }}
                >
                  <span
                    style={{
                      boxShadow: `inset 0 -0.175rem ${fC.color}`,
                      backgroundSize: "1px 1rem",
                    }}
                  >
                    {fC.title}
                  </span>
                </Typography>
                <Box
                  marginLeft={{ xs: 2, sm: 0 }}
                  maxWidth={{ xs: "100%", sm: "25ch" }}
                >
                  <Typography variant="p" align="center">
                    {fC.text}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Stack>
      </>
    );
  };
  const NewHeader = () => {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
      setMobileOpen((prevState) => !prevState);
    };
    const drawer = (
      <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
        <Typography variant="h6" sx={{ my: 2 }}>
          Navigation
        </Typography>
        <Divider />
        {user.isAuthenticated
          ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: "1",
                gap: "1rem",
                my: "1rem",
                height: "75vh",
              }}
            >
              <PillButtonLink
                alignSelf="flex-end"
                component={RouterLink}
                to={"/editor"}
                buttonTitle={"Editor"}
                buttonIcon={<EditNoteIcon />}
              />
              <PillButtonLink
                alignSelf="flex-end"
                component={RouterLink}
                to={"/Presentation"}
                buttonTitle={"Presentation"}
                buttonIcon={<SlideshowIcon />}
              />
              <PillButtonLink
                alignSelf="flex-end"
                boxStyles={{ margin: "auto" }}
                component={RouterLink}
                clickAction={() => {
                  localStorage.removeItem("Swseng_token");
                  dispatch(logoutUser());
                }}
                buttonTitle={"Logout"}
                buttonIcon={<LogoutIcon />}
              />
            </Box>
          )
          : (
            <Box
              sx={{
                alignSelf: "center",
                display: "flex",
                marginRight: "2rem",
              }}
            >
              <PillButtonLink
                component={RouterLink}
                to="/Signin"
                buttonTitle={"Sign in"}
              />
            </Box>
          )}
      </Box>
    );

    return (
      <>
        <Box
          sx={{
            top: "0px",
            alignContent: "baseline",
            display: "flex",
            height: "auto",
            justifyContent: "space-between",
            zIndex: "3",
            px: 2,
            pt: "13px",
          }}
        >
          <Box
            sx={{
              alignSelf: "start",
            }}
          >
            <img
              src={SwsengLogo}
              style={{
                width: "auto",
                maxHeight: "56px",
              }}
            />
          </Box>
          {user.isAuthenticated
            ? (
              <Box sx={{ alignSelf: "center" }}>
                <IconButton
                  size="large"
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: "none" } }}
                >
                  <MenuIcon fontSize="inherit" />
                </IconButton>
                <Box
                  display={{ xs: "none", sm: "flex" }}
                  sx={{
                    flexGrow: "1",
                    gap: "1rem",
                    justifyContent: "space-evenly",
                  }}
                >
                  <PillButtonLink
                    boxStyles={{ alignSelf: "flex-start" }}
                    component={RouterLink}
                    to={"/editor"}
                    buttonTitle={"Editor"}
                    buttonIcon={<EditNoteIcon />}
                  />
                  <PillButtonLink
                    component={RouterLink}
                    to={"/Presentation"}
                    buttonTitle={"Presentation"}
                    buttonIcon={<SlideshowIcon />}
                  />
                  <PillButtonLink
                    boxStyles={{ alignSelf: "flex-start" }}
                    component={RouterLink}
                    clickAction={() => {
                      localStorage.removeItem("Swseng_token");
                      dispatch(logoutUser());
                    }}
                    buttonTitle={"Logout"}
                    buttonIcon={<LogoutIcon />}
                  />
                </Box>
              </Box>
            )
            : (
              <Box
                sx={{
                  alignSelf: "center",
                  display: "flex",
                  marginRight: "2rem",
                }}
              >
                <PillButtonLink
                  component={RouterLink}
                  to="/Signin"
                  buttonTitle={"Sign in"}
                />
              </Box>
            )}
        </Box>
        <nav>
          <Drawer
            anchor={"right"}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                padding: "0rem 2rem 0rem 3rem",
                boxSizing: "border-box",
                width: { xs: "fit-content", sm: "50%" },
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </>
    );
  };

  const Testimonials = () => {
    return (
      <Box
        display="flex"
        justifyContent={"center"}
      >
        <Box maxWidth={"920px"}>
          <Typography
            variant="h3"
            fontSize={"2rem"}
            textAlign="center"
            lineHeight={"1.1"}
            mb={6}
          >
            {'"With Swseng I can write scripts that would never have been possible before,  '}

            <span
              style={{
                backgroundColor: swsengPallet.pink,
                padding: "0 0.2225rem",
                display: "inline-block",
                lineHeight: "1",
                textWrap: "nowrap",
              }}
            >
              breaking form,
            </span>{" "}
            {"convention, and "}
            <span
              style={{
                backgroundColor: swsengPallet.yellow,
                padding: "0 0.1225rem",
                display: "inline-block",
                lineHeight: "1",
                textWrap: "nowrap",
              }}
            >
              boundaries
            </span>
            {". There's nothing else like it.\""}
          </Typography>
          <Typography
            variant="h4"
            textAlign="center"
            fontSize={"2rem"}
            mb={6}
          >
            - Alex Rubin | <i>Playwright</i>
          </Typography>
        </Box>
        <Box>
        </Box>
      </Box>
    );
  };

  const NewSubscribe = () => {
    return (
      <Box
        mb={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
        }}
      >
        <Typography
          variant="h3"
          fontSize={"2.25rem"}
          mb={2}
        >
          The future of stage writing is here.
        </Typography>
        <Box
          sx={{ maxWidth: "450px", alignSelf: "center", textAlign: "center" }}
        >
          <Typography
            pb={"1rem"}
            sx={{
              lineHeight: "1.125",
              letterSpacing: "0.0425rem",
              fontSize: "1.25rem",
              paddingBottom: "2rem",
            }}
            variant="p1"
          >
            {"We see a future where whole new worlds of entertainment are built from Swseng's limitless freedom. We  want authors to make work new."}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "fit-content",
            alignSelf: "center",
          }}
          my={3}
          display={"flex"}
          justifyContent={"center"}
        >
          <CTAButton
            component={RouterLink}
            to={"/Register"}
            buttonTitle={"Sign up"}
            alignSelf={"center"}
            boxStyles={{ paddingRight: "0rem", margin: "10px" }}
          >
            <HoveringNeonElement buttonLabel={"Sign up"} />
          </CTAButton>
          <Typography
            sx={{
              lineHeight: "1.125",
              letterSpacing: "0.0425rem",
              fontSize: "1.25rem",
              textAlign: "center",
              alignSelf: "center",
            }}
            variant="p"
          >
            for free today.
          </Typography>
        </Box>
      </Box>
    );
  };

  const Footer = () => {
    return (
      <Box
        display={"flex"}
        justifyContent={{ xs: "center", sm: "space-evenly" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <Typography
          mb={2}
          variant="p"
          alignSelf={"center"}
        >
          Copyright Swseng LLC
        </Typography>
      </Box>
    );
  };

  const BackgroundBars = ({ imgSrc, imgStyles, position }) => {
    return (
      <Box display="flex" justifyContent={position} my={"6rem"} width={"100%"}>
        <img
          src={imgSrc}
          style={{
            width: "auto",
            maxHeight: "95px",
            minHeight: "60px",
            height: "12vw",
            ...imgStyles,
          }}
        />
      </Box>
    );
  };

  const Hero = () => {
    return (
      <Stack
        direction={{ xs: "column", sm: "row" }}
        pt={"5rem"}
        mb={5}
        overflow={{ xs: "revert", sm: "clip" }}
        boxShadow={{
          xs: "none",
          sm: "inset -2rem 2rem 2rem white",
          md: "inset -3rem 3rem 3rem white",
          lg: "none",
        }}
        sx={{
          justifyContent: "flex-start",
        }}
      >
        <Stack
          mb={1}
          pb={{ xs: "1.5rem", md: "0rem" }}
          direction="column"
          maxWidth={{ xs: "95%" }}
          minWidth={{ sm: "365px" }}
          alignSelf={{ xs: "center", md: "inherit" }}
        >
          <Typography variant="h1">
            Write
          </Typography>
          <Typography
            pb={4}
            variant="h1"
          >
            Like Life.
          </Typography>
          <Typography
            mb={4}
            variant="p1"
            width={{ xs: "100%" }}
            align="left"
          >
            Swseng (/swɪ́ŋ/) is a revolutionary writing software that uses
            <span
              style={{
                backgroundColor: swsengPallet.pink,
                padding: "0 0.2225rem",
                margin: "0.1225rem",
                textWrap: "nowrap",
              }}
            >
              horizontal composition
            </span>
            to create
            <span
              style={{
                backgroundColor: swsengPallet.yellow,
                padding: "0 0.2225rem",
                margin: "0.1225rem",
                textWrap: "nowrap",
              }}
            >
              layered dialogue
            </span>, arrange across multiple spaces, and develop
            <span
              style={{
                backgroundColor: swsengPallet.blue,
                padding: "0 0.2225rem",
                margin: "0.1225rem",
                textWrap: "nowrap",
              }}
            >
              emergent rhythms.
            </span>
          </Typography>
          <Box
            display={{ xs: "none", sm: "flex" }}
            alignItems={"center"}
            gap={"0.5rem"}
          >
            <CTAButton
              boxStyles={{ marginLeft: "1rem" }}
              component={RouterLink}
              to={"/Register"}
              buttonTitle={"Sign up"}
              alignSelf={"center"}
            >
              <HoveringNeonElement buttonLabel={"Sign up"} />
            </CTAButton>
            <Typography variant="p1">
              and start writing like life.
            </Typography>
          </Box>
        </Stack>
        <Box
          sx={{ perspective: "1500px" }}
          alignSelf={"center"}
          zIndex={"-1"}
          pr={{ xs: 3, md: 6, lg: 8 }}
          pb={"2rem"}
          height={"auto"}
          width={"100%"}
          minWidth={{ xs: "100%", sm: "700px" }}
        >
          <img
            style={{
              height: "inherit",
              width: "inherit",
              borderRadius: "8px",
              transform: "rotateX(2deg) rotateY(-15deg) rotateZ(1deg)",
              boxShadow: `#aaa 1px 1px 1px,
               rgba(117, 211, 229,.65) -16px -16px 12px -8px,
               rgba(251, 178, 43,.65) 16px 16px 14px -3px`,
            }}
            src={Swseng}
          />
        </Box>
        <Box
          display={{ xs: "flex", sm: "none" }}
          alignItems={"center"}
          gap={"0.5rem"}
          alignSelf={"center"}
          pt={2}
        >
          <CTAButton
            component={RouterLink}
            to={"/Register"}
            buttonTitle={"Sign up"}
            alignSelf={"center"}
          >
            <HoveringNeonElement buttonLabel={"Sign up"} />
          </CTAButton>
          <Typography variant="p1">
            and start writing like life.
          </Typography>
        </Box>
      </Stack>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NewHeader />
      <Container
        maxWidth="lg"
        py={5}
        sx={{
          overflowX: "hidden",
        }}
      >
        <Hero />
        <BackgroundBars imgSrc={OrangeLogo} position={"flex-start"} />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent={"center"}
          margin="auto"
        >
          <Box maxWidth={"550px"} alignSelf={"center"} mb={8}>
            <Typography variant="h3" fontSize={"2.5rem"} textAlign="center">
              Innovative, Expressive Writing Software
            </Typography>
          </Box>
          <Box alignSelf={"center"} marginBottom={{ xs: "0", sm: "5rem" }}>
            <FeatureCard featureCardContent={featureCardContent} />
          </Box>
        </Box>
        <BackgroundBars imgSrc={RedLogo} position={"center"} />
        <Testimonials />
        <BackgroundBars
          imgSrc={BlueLogo}
          imgStyles={{ transform: "rotateY(180deg)" }}
          position={"flex-end"}
        />
        <NewSubscribe />
        <Box height={"6rem"} />
        <Footer />
      </Container>
    </ThemeProvider>
  );
}

export default Landing;
