import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PillButtonLink from "../Common/PillButtonLink";
import SwsengLogo from "../../static/images/SWSENG_Logo_Cropped.png";
import {
  Box,
  Container,
  createTheme,
  CssBaseline,
  IconButton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import AliceRegular from "../../static/fonts/Alice-Regular.ttf";
import LibreFranklinRegular from "../../static/fonts/LibreFranklin-Regular.ttf";
const theme = createTheme({
  typography: {
    h1: {
      fontFamily: "Alice",
      fontSize: "5.0rem",
      lineHeight: "0.80",
      letterSpacing: "-0.30rem",
      fontWeight: "500",
      color: "#363635",
    },
    h2: {
      fontFamily: "Alice",
      letterSpacing: "-0.20rem",
    },
    h3: {
      fontFamily: "Alice",
      lineHeight: "0.85",
      letterSpacing: "-0.13rem",
      textAlign: "center",
    },
    h4: {
      fontFamily: "Alice",
      letterSpacing: "-0.13rem",
    },
    h5: {
      fontFamily: "Alice",
      letterSpacing: "-0.1rem",
    },
    p: {
      fontFamily: "LibreFranklin",
    },
    p1: {
      fontFamily: "LibreFranklin",
      lineHeight: "1.125",
      letterSpacing: "0px",
      // letterSpacing: "0.0425rem",
      fontSize: "1.25rem",
      textWrap: "balance",
      margin: "0 0",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Alice';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Alice'), local('Alice-Regular'), url(${AliceRegular}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'LibreFranklin';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('LibreFranklin'), local('LibreFranklin-Regular'), url(${LibreFranklinRegular}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const HoveringNeonElement = ({ buttonLabel }) => {
  return (
    <Box
      sx={{
        background:
          `linear-gradient(-45deg, #7fdaff, #ffa63d, #ff3d77, #ffa63d)`,
        backgroundSize: "500%",
        animation: `gradient 10s linear infinite`,
        position: "absolute",
        marginTop: ".5rem",
        zIndex: "-1",
        height: "1.75rem",
        filter: "blur(.5rem)",
        opacity: "1",
        // width: "8ch",
      }}
    >
      {buttonLabel}
    </Box>
  );
};
export default function AtlanticTheatre() {
  // Demo 3: https://www.swseng.io/p/2f191bb1-b9a6-4ce2-828f-3faf9cb7944c
  //
  // Demo 4: https://www.swseng.io/p/d3c5c10d-b978-401d-a391-e0c8e48789f2
  //
  // Demo 5: https://www.swseng.io/p/a694269e-8800-477c-b320-4be9d211543b
  //
  // Demo 6: https://www.swseng.io/p/71935c49-c879-4f1e-bd93-83a752b02d8c
  //
  // Demo 7: https://www.swseng.io/p/79e40345-e796-4a24-a828-44f26be39e43

  const demoList = [
    {
      label: "Demo 3",
      uuid: "2f191bb1-b9a6-4ce2-828f-3faf9cb7944c",
    },
    {
      label: "Demo 4",
      uuid: "d3c5c10d-b978-401d-a391-e0c8e48789f2",
    },
    {
      label: "Demo 5",
      uuid: "a694269e-8800-477c-b320-4be9d211543b",
    },
    {
      label: "Demo 6",
      uuid: "71935c49-c879-4f1e-bd93-83a752b02d8c",
    },
    {
      label: "Demo 7",
      uuid: "79e40345-e796-4a24-a828-44f26be39e43",
    },
  ];
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="sm" pb={"4rem"}>
        <Box
          pt={4}
          pb={8}
          sx={{
            alignSelf: "center",
          }}
        >
          <IconButton
            sx={{ borderRadius: 2, padding: 0 }}
            component={RouterLink}
            to={"/"}
          >
            <img src={SwsengLogo} style={{ height: "56px", width: "auto" }} />
          </IconButton>
        </Box>
        <Typography variant="h3" mb={2}>
          Atlantic Theatre
        </Typography>
        <Typography variant="h5" mb={1} textAlign={"center"}>
          Wednesday October 9th
        </Typography>

        <Typography variant="p">
          {"Below are a selection of scripts we will be working with tonight. Although you may be tempted don't skip ahead just yet, we'll let you know where we are going."}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: "1",
            gap: "1.25rem",
            my: "1rem",
          }}
        >
          {demoList.map((demo, index) => {
            return (
              <PillButtonLink
                key={demo.label}
                alignSelf={"center"}
                // boxStyles={{ marginRight: "1rem" }}
                component={RouterLink}
                to={`/p/${demo.uuid}`}
                buttonTitle={demo.label}
              // buttonIcon={<EditNoteIcon />}
              />
            );
          })}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flexGrow: "1",
            gap: "1rem",
            mt: "3rem",
            mb: "3rem",
          }}
        >
          <Typography
            variant="p"
            alignSelf={"center"}
            textAlign={"center"}
            maxWidth={"30ch"}
          >
            {"We would love your input on Swseng and what we can do to make it better!"}
          </Typography>
          <PillButtonLink
            alignSelf={"center"}
            // boxStyles={{ marginRight: "1rem" }}
            component={RouterLink}
            to={`https://docs.google.com/forms/d/e/1FAIpQLSeob4wetDgHujhQNiLV1d1E59_5yfHfKAvPi-t4hl-DyPZA4A/viewform?usp=sf_link`}
            buttonTitle={"Exit Survey"}
          // buttonIcon={<EditNoteIcon />}
          />
        </Box>
      </Container>
    </ThemeProvider>
  );
}
