import React, { useEffect, useRef, useState } from "react";
import PresentationElements from "./PresentationElements.jsx";
import PresentationSections from "./PresentationSections.jsx";
import { getElementsInSection } from "../Common/Utils/ElementsAndSectionUtils.js";
import { Typography } from "@mui/joy";
import SwipingArrows from "../Common/SwipingArrows.jsx";
function PresentationStaff(
  {
    elements,
    sections,
    dialogue,
    page,
    setPage,
    setDialogue,
    characterFocus,
    setCharacterFocus,
    handleShowSectionModal,
    selectedElement,
    documentId,
    setSectionExpanded,
    sectionExpanded,
  },
) {

  const staffElements = useRef(null);
  const [staffHeight, setStaffHeight] = useState("100vh");
  useEffect(() => {
    if (staffElements.current !== null) {
      // console.log("clientHeight " + staffElements.current.clientHeight);
      setStaffHeight(`calc(${staffElements.current.clientHeight}px + 5rem)`);
    }
  }, [staffElements.current, sectionExpanded]);

  const touchStart = useRef(null);
  const touchEnd = useRef(null);
  const [touchMoveDistance, setTouchMoveDistance] = useState(0);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 150;
  // Set a max distance that the staff container div can travel
  // maybe use the threshold to determine if it should move
  // and have the container only move by a percentage?
  const onTouchStart = (e) => {
    setTouchMoveDistance(0);
    // touchMove.current = null;
    touchEnd.current = null;
    touchStart.current = e.targetTouches[0].clientX;
  };

  const onTouchMove = (e) => {
    // console.log(e.touches[0].clientX - touchStart.current);
    const distance = e.touches[0].clientX - touchStart.current;
    // dont allow back swipe when on the first page
    if (Math.abs(distance) < minSwipeDistance) {
      setTouchMoveDistance(0);
      touchEnd.current = e.targetTouches[0].clientX;
    } else if (!(distance > 0 && page === 1)) {
      setTouchMoveDistance(distance);
      touchEnd.current = e.targetTouches[0].clientX;
    }
  };

  const onTouchEnd = () => {
    if (!touchStart.current || !touchEnd.current) return;
    const distance = touchStart.current - touchEnd.current;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      // console.log("swipe", isLeftSwipe ? "left" : "right");
      setPage(page + 1);
      setTouchMoveDistance(0);
      // touchEnd.current = null;
    } else if (isRightSwipe && page > 1) {
      setPage(page - 1);
      // console.log("swipe", isLeftSwipe ? "left" : "right");
      setTouchMoveDistance(0);
      // touchEnd.current = null;
    }
    // add your conditional logic here
    setTouchMoveDistance(0);
  };
  useEffect(() => {
    const allSections = sections.map((sec) => sec.sectionName)
    setSectionExpanded(allSections);
  }, [documentId]);


  const sectionElementsToExclude = sections.map((section) => {
    return getElementsInSection(page, section);
  }).flat();
  return (
    <div className="editor-container">
      {(elements.length === 0 && sections.length === 0)
        ? (
          <div
            style={{
              padding: "1rem 0 1rem 0",
              width: "113ch",
              textAlign: "center",
              fontSize: "inherit",
              fontFamily: "Courier New",
              marginLeft: "12ch",
            }}
          >
            <Typography
              level="h2"
              sx={{ letterSpacing: "1px", color: "rgba(0,0,0,0.6)" }}
            >
              Open a file
            </Typography>
          </div>
        )
        : (
          <>
            <div
              className="staff-ruler"
              style={{ height: staffHeight }}
            />
            <div className="canvas-container">
              <SwipingArrows touchMoveDistance={touchMoveDistance} />
              <div
                style={{
                  fontSize: "inherit",
                  position: "relative",
                  left: "120ch",
                  top: "0",
                  width: "fit-content",
                }}
              >
                <p>
                  {page}
                </p>
              </div>
              <div
                ref={staffElements}
                style={{
                  touchAction: "pan-y",
                  transition: "transform .33s ease-out",
                  transform: `translateX(${
                    touchMoveDistance === 0
                      ? "0"
                      : touchMoveDistance > 0
                      ? "5"
                      : "-5"
                  }%)`,
                }}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
              >
                <PresentationSections
                  setSectionExpanded={setSectionExpanded}
                  sectionExpanded={sectionExpanded}
                  sections={sections}
                  dialogue={dialogue}
                  setDialogue={setDialogue}
                  characterFocus={characterFocus}
                  setCharacterFocus={setCharacterFocus}
                  page={page}
                  selectedElement={selectedElement}
                  // Empty Measure Show Section Modals
                  handleShowSectionModal={handleShowSectionModal}
                />
                <PresentationElements
                  sectionElementsToExclude={sectionElementsToExclude}
                  elements={elements}
                  dialogue={dialogue}
                  page={page}
                  setDialogue={setDialogue}
                  characterFocus={characterFocus}
                  setCharacterFocus={setCharacterFocus}
                  selectedElement={selectedElement}
                />
              </div>
            </div>
          </>
        )}
    </div>
  );
}

export default PresentationStaff;
