export function measureRangeCheck(measureRanges, page) {
  for (let i = 0; i < measureRanges.length; i++) {
    let mR = measureRanges[i];
    if (mR[0] === 1 && mR[1] === -1) {
      return true;
    } else if (mR[0] <= page && mR[1] >= page) {
      return true;
    } else if (mR[0] <= page && mR[1] === -1) {
      return true;
    }
  }
  return false;
}

export function getElementsInSection(page, section) {
  return section.elements.filter((element) => 
    measureRangeCheck(element.measureRanges, page)
  ).map((e) => e.elementName);
}


export default { measureRangeCheck, getElementsInSection };
