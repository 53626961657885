import React, { useEffect, useState } from "react";
import StaffElements from "../Staff/StaffElements";
import Copyright from "../Common/Copyright.jsx";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import ToolBar from "../Layout/ToolBar.jsx";
// import convention from "../../static/convention.js";

// import {
//   dialogueStub,
//   elementStub,
//   sectionStub,
//   titleStub,
// } from "../../static/dialogue/Convertion.js";
// import { dialogueStub, sectionStub} from "./static/dialogue/dialogueGirls.js";
// import { dialogueStub, sectionStub } from "./static/dialgoue/dialogueRocco";
// import { dialogueStub, sectionStub } from "./static/dialgoue/dialogueElectro";
// import {
//   dialogueStub,
//   elementStub,
//   sectionStub,
//   title,
// } from "../../static/dialogue/dialogueSailing.js";
// import { dialogueStub, sectionStub } from "./static/dialgoue/dialogueEmpty.js";
import FormatConverter from "../Common/FormatConverter.jsx";
import SectionModal from "../Modals/Section/SectionModal.jsx";
import ElementModal from "../Modals/Element/ElementModal.jsx";
import FileModal from "../Modals/File/FileModal.jsx";
import NewFileModal from "../Modals/File/NewFileModal.jsx";
import FileNameModal from "../Modals/File/FileNameModal.jsx.jsx";

import { logoutUser } from "../User/userSlice.js";
import { loadDocument, clearDocument } from "../Document/documentSlice.js";
import { useNavigate } from "react-router-dom";
import { dialogActionsClasses } from "@mui/joy";

const emptySectionAuditorium = {
  "sectionName": "AUDITORIUM",
  "elements": [],
};

function Editor() {
  const user = useSelector((state) => state.user);
  const documentState = useSelector((state) => state.document);
  const documentId = useSelector((state) => state.document.id);
  const metadata = useSelector((state) => state.document.metadata);
  const [title, setTitle] = useState(documentState.title);
  const [dialogue, setDialogue] = useState(documentState.dialogue);
  const [staffElements, setStaffElements] = useState({
    // elements: [...new Set(dialogueStub.map((item) => item.element))],
    // elements: [],
    // sections: [emptySectionAuditorium],
    elements: documentState.elements,
    sections: documentState.sections,
  });
  const [measure, setMeasure] = useState(1);
  const [characterFocus, setCharacterFocus] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // console.log(dialogue.reduce((accum, currentValue) => currentValue.message.length > accum ? currentValue.message.length : accum, 0))
  // useEffect(() => {
  //   if (documentState.documentId !== null) {
  //     setDocumentId(documentState.documentId);
  //     setTitle(documentState.title);
  //     setDialogue(documentState.dialogue);
  //     setStaffElements({
  //       elements: documentState.elements,
  //       sections: documentState.sections,
  //     })
  //   }
  // })

  const [showSectionModal, setShowSectionModal] = useState({
    open: false,
    tabIndex: "1",
    section: null,
  });
  const handleCloseSectionModal = () =>
    setShowSectionModal({ ...showSectionModal, open: false, section: null });
  const handleShowSectionModal = (nextTabIndex, nextSection) =>
    setShowSectionModal({
      open: true,
      tabIndex: nextTabIndex,
      section: nextSection,
    });

  const [showElementModal, setShowElementModal] = useState({
    open: false,
    tabIndex: "1",
  });
  const handleCloseElementModal = () =>
    setShowElementModal({ ...showElementModal, open: false });
  const handleShowElementModal = (nextTabIndex) =>
    setShowElementModal({ open: true, tabIndex: nextTabIndex });

  const [showFileModal, setShowFileModal] = useState(false);
  const handleCloseFileModal = () => setShowFileModal(false);
  const handleShowFileModal = () => setShowFileModal(true);

  const [showNewFileModal, setShowNewFileModal] = useState(false);
  const [showFileNameModal, setShowFileNameModal] = useState(false);
  // const handleCloseNewFileModal = () => setShowNewFileModal(false);
  // const handleShowNewFileModal = () => setShowNewFileModal(true);

  const handleClearCharacterFocus = () => {
    setCharacterFocus("");
  };

  // const handleLoadConvention = () => {
  //   const conventionFile = {
  //     title: convention.title,
  //     dialogue: convention.dialogue,
  //     sections: convention.sections,
  //     elements: convention.elements,
  //     measure: null,
  //     id: null,
  //     created_at: null,
  //     updated_at: null,
  //     owner: null,
  //     viewer: [],
  //   };
  //   dispatch(loadDocument(conventionFile));
  // };
  //
  // React.useEffect(() => {
  //   handleLoadConvention();
  // }, [])

  const handleLogOut = () => {
    localStorage.removeItem("Swseng_token");
    dispatch(clearDocument());
    dispatch(logoutUser());
    navigate("/");
  };

  async function saveFile(newTitle) {
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${user.token}`,
      },
    };
    const payload = {
      title: newTitle ? newTitle : title,
      dialogue: dialogue,
      sections: staffElements.sections,
      elements: staffElements.elements,
      metadata: metadata,
    };
    if (documentId === null) {
      try {
        const response = await axios.post(
          apiEndpoint + `/document/`,
          payload,
          config,
        );
        console.log(response.data);
        dispatch(loadDocument(response.data));
        // setDocumentId(response.data.id);
        setTitle(response.data.title);
        // setData(response.data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await axios.patch(
          apiEndpoint + `/document/${documentId}/`,
          payload,
          config,
        );
        console.log(response.data);
        dispatch(loadDocument(response.data));
        // setData(response.data);
      } catch (error) {
        console.log(error);
      }
    }
  }

  const handleSaveFile = (newTitle) => {
    if (title === "") {
      setShowFileNameModal(true);
    } else {
      saveFile(newTitle);
    }
  };

  const handleDownloadFile = () => {
    const payload = {
      title: title,
      dialogue: dialogue,
      sections: staffElements.sections,
      elements: staffElements.elements,
    };
    const blob = new Blob([JSON.stringify(payload, null, 2)], {
      type: "application/json",
    });
    // const blob = payload.blob();
    // Create blob link to download
    const url = window.URL.createObjectURL(
      new Blob([blob]),
    );
    const link = document.createElement("a");
    link.href = url;
    const thisDocumentId = (documentId == null) ? "" : `_${documentId}`;
    link.setAttribute(
      "download",
      `${title}${thisDocumentId}.json`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };
  const handleSetFileToEmpty = () => {
    // setDocumentId(null);
    setTitle("");
    setDialogue([]);
    setStaffElements({
      elements: [],
      sections: [],
    });
    dispatch(clearDocument());
  };

  const handleCreateNewFile = () => {
    if (
      staffElements.sections.length !== 0 ||
      staffElements.elements.length !== 0 || title !== ""
    ) {
      setShowNewFileModal(true);
    }
  };

  return (
    <div style={{ height: "100vh", maxWidth: "100vw", overflow: "hidden" }}>
      {
        /*
      <FormatConverter
        dialogue={dialogue}
        sections={staffElements.sections}
        elements={staffElements.elements}
        />

      */
      }
      <ToolBar
        handleLogOut={handleLogOut}
        handleCreateNewFile={handleCreateNewFile}
        handleDownloadFile={handleDownloadFile}
        handleSaveFile={handleSaveFile}
        handleShowFileModal={handleShowFileModal}
        handleShowSectionModal={handleShowSectionModal}
        // handleShowEditSection={handleShowEditSection}
        handleShowElementModal={handleShowElementModal}
        measure={measure}
        setMeasure={setMeasure}
        handleClearCharacterFocus={handleClearCharacterFocus}
        setTitle={setTitle}
        title={title}
        documentId={documentId}
      />
      <StaffElements
        staffElements={staffElements}
        setStaffElements={setStaffElements}
        dialogue={dialogue}
        setDialogue={setDialogue}
        characterFocus={characterFocus}
        setCharacterFocus={setCharacterFocus}
        elements={staffElements.elements}
        sections={staffElements.sections}
        page={measure}
        documentId={documentId}
        // Empty Measure Show Section Modals
        handleShowSectionModal={handleShowSectionModal}
      />
      <div className="bottom-toolbar-container">
        <Copyright />
      </div>
      <SectionModal
        show={showSectionModal}
        handleClose={handleCloseSectionModal}
        handleShow={handleShowSectionModal}
        staffElements={staffElements}
        setStaffElements={setStaffElements}
        setMeasure={setMeasure}
        measure={measure}
      />
      <ElementModal
        show={showElementModal}
        handleClose={handleCloseElementModal}
        handleShow={handleShowElementModal}
        staffElements={staffElements}
        setStaffElements={setStaffElements}
        dialogue={dialogue}
        setDialogue={setDialogue}
        page={measure}
      />
      <FileModal
        documentId={documentId}
        // Moved documentId to redux
        // setDocumentId={setDocumentId}
        title={title}
        setTitle={setTitle}
        show={showFileModal}
        handleClose={handleCloseFileModal}
        setDialogue={setDialogue}
        setStaffElements={setStaffElements}
      />
      <NewFileModal
        open={showNewFileModal}
        setOpen={setShowNewFileModal}
        setTitle={setTitle}
        title={title}
        handleSaveFile={handleSaveFile}
        handleSetFileToEmpty={handleSetFileToEmpty}
      />
      <FileNameModal
        open={showFileNameModal}
        setOpen={setShowFileNameModal}
        showSharedWithMe={false}
        title={title}
        setTitle={setTitle}
        saveFile={saveFile}
      />
    </div>
  );
}

export default Editor;
