import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Copyright from "../Common/Copyright";
import PresentationStaff from "./PresentationStaff";
import axios from "axios";
import FileModal from "../Modals/File/FileModal.jsx";
import ShareModal from "../Modals/Share/ShareModal.jsx";
import PresentationToolbar from "./PresentationToolbar.jsx";

function Presentation() {
  const documentState = useSelector((state) => state.document);
  const user = useSelector((state) => state.user);
  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
  const localToken = localStorage.getItem("Swseng_Token");

  const [showFileModal, setShowFileModal] = React.useState(false);
  const [measure, setMeasure] = React.useState(1);
  const [selectedElement, setSelectedElement] = React.useState(null);
  const [showShareModal, setShowShareModal] = React.useState(false);
  const [sectionExpanded, setSectionExpanded] = React.useState([]);

  const handleCloseShareModal = () => setShowShareModal(false);
  const handleShowShareModal = () => setShowShareModal(true);
  const handleCloseFileModal = () => setShowFileModal(false);
  const handleShowFileModal = () => setShowFileModal(true);

  const staffElements = {
    elements: documentState.elements,
    sections: documentState.sections,
  };
  const { title, dialogue, id: documentId, viewer } = documentState;

  const selectedElementOptions = documentState.elements.map((element) => ({
    value: element,
    label: element,
  }));

  // Fix and remove this from: Presentation, Landing, FileModal, ShareModal
  async function getUser() {
    const data = new FormData(event.currentTarget);
    const username = data.get("email");
    const password = data.get("password");
    const body = JSON.stringify({ username, password });
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": localToken,
      },
    };
    try {
      const response = await axios.post(
        apiEndpoint + "/user",
        body,
        config,
      );
      // console.log(response.data);
      // localStorage.setItem("Swsweng_token", "Token " + response.data.token )
      return response.data;
    } catch (error) {
      // console.error(error);
    }
  }

  if (localToken) {
    // console.log(localToken);
    getUser();
  }
  React.useEffect(() => {
    const user_viewer = documentState.viewer.find((v) =>
      v.username === user.username
    );
    if (user_viewer !== undefined) {
      const nextSectionsExpanded = staffElements.sections.filter((s) =>
        s.elements.find((se) => se.elementName === user_viewer.actor_element)
      ).map((s) => s.sectionName);
      setSectionExpanded(nextSectionsExpanded);
      return setSelectedElement(user_viewer.actor_element);
    }
    setSelectedElement(null);
  }, [documentState.id]);


  const handleSelectElement = (e) => {
    if (e === null) {
      setSelectedElement(null);
      setSectionExpanded([]);
    } else {
      const nextSectionsExpanded = staffElements.sections.filter((s) =>
        s.elements.find((se) => se.elementName === e.value)
      ).map((s) => s.sectionName);
      setSectionExpanded(nextSectionsExpanded);
      setSelectedElement(e.value);
    }
  };

  return (
    <div style={{ display: "grid", height: "100vh" }}>
      <PresentationToolbar
        selectedElementOptions={selectedElementOptions}
        selectedElement={selectedElement}
        measure={measure}
        setMeasure={setMeasure}
        handleSelectElement={handleSelectElement}
        handleShowShareModal={handleShowShareModal}
        handleShowFileModal={handleShowFileModal}
      />
      <PresentationStaff
        elements={staffElements.elements}
        sections={staffElements.sections}
        dialogue={dialogue}
        page={measure}
        setPage={setMeasure}
        sectionExpanded={sectionExpanded}
        setSectionExpanded={setSectionExpanded}
        selectedElement={selectedElement}
        documentId={documentId}
      />
      <div className="bottom-toolbar-container">
        <Copyright />
      </div>
      <FileModal
        show={showFileModal}
        handleClose={handleCloseFileModal}
        showSharedWithMe={true}
      />
      <ShareModal
        show={showShareModal}
        handleClose={handleCloseShareModal}
        handleShow={handleShowShareModal}
        title={title}
      />
    </div>
  );
}

export default Presentation;
