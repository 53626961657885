import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  createTheme,
  FormControl,
  InputLabel,
  TextField,
  ThemeProvider,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
function PresentationMeasureControl({ measure, setMeasure }) {
  const [nextMeasure, setNextMeasure] = useState(measure);

  React.useEffect(() => {
    // function handleScroll(e) {
    //   console.log(window.scrollX, window.scrollY);
    // }
    // window.addEventListener("scroll", handleScroll);
    // return () => window.removeEventListener("scroll", handleScroll);
    function handleKeyDown(e) {
      // stopPropogation();
      // console.log(e);
      if (e.keyCode === 37) {
        e.preventDefault();
        if (measure > 1) {
          handlePrevNext(measure - 1);
        }
      } else if (e.keyCode === 39) {
        e.preventDefault();
        handlePrevNext(measure + 1);
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [measure]);

  const handleOnBlur = (e) => {
    // console.log("Target Value " + e.target.value)
    let parsedValue = parseInt(e.target.value);
    // console.log("Parsed target value" + parsedValue)
    if (parsedValue > 0 && !isNaN(parsedValue)) {
      setMeasure(parsedValue);
      setNextMeasure(parsedValue.toString());
    } else {
      // console.log("Measure to string " + measure.toString())
      setNextMeasure(measure.toString());
    }
  };

  const handlePrevNext = (value) => {
    setNextMeasure(value);
    setMeasure(value);
  };
  const theme = createTheme({
    palette: {
      ochre: {
        main: "#c8c8c8",
        light: "#f8f8f8",
        dark: "#979798",
        contrastText: "#000",
      },
    },
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            "&:hover, &.Mui-focusVisible": {
              backgroundColor: "#d1eaff",
              boxShadow: "none",
            },
            backgroundColor: "#fff",
            border: "1px solid transparent",
            fontFamily: "Google Sans, sans-serif",
            // letterSpacing: "1px",
            // fontSize: "1.2rem",
            minWidth: "24px",
            boxShadow: "none",
            textTransform: "none",
            margin: "0 0 0 0",
            color: "#000",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            height: "36px",
            border: "none",
            marginBottom: "0",
            marrginTop: "0",
          },
        },
      },
    },
  });

  return (
    <FormControl>
      <Box
        style={{
          display: "inline-flex",
          border: "1px solid rgb(217, 217, 217)",
          width: "fit-content",
          borderRadius: "14px",
          paddingBottom: "0px",
          marginBottom: "0px",
          height: "fit-content",
          alignSelf: "center",
        }}
      >
        <ThemeProvider theme={theme}>
          <InputLabel
            sx={{
              // display: "grid",
              top: "-2.25rem",
              left: "inherit",
              position: "absolute",
              // transformOrigin: "top",
            }}
          >
            Measure
          </InputLabel>
          <Button
            sx={{ my: 0, py: 0, borderRadius: "14px 2px 2px 14px" }}
            color="ochre"
            disabled={measure === 1}
            onClick={() => {
              handlePrevNext(() => measure - 1);
            }}
          >
            <RemoveIcon />
          </Button>
          <TextField
            sx={{
              // width: "fit-content",
              "& .MuiInputBase-input:focus": {
                backgroundColor: "#fff",
                padding: "4px 14px 4px 14px",
              },
              input: { textAlign: "center", minWidth: "3ch", maxWidth: "4ch" },
            }}
            value={nextMeasure}
            // variant="outlined"
            onChange={(e) => setNextMeasure(e.target.value)}
            onBlur={(e) => handleOnBlur(e)}
            onKeyUp={(e) => {
              if (e.key == "Enter") {
                handleOnBlur(e);
                e.target.blur();
              }
            }}
            name="numberformat"
            id="formatted-numberformat-input"
            InputProps={{
              min: 1,
            }}
          />
          <Button
            sx={{ my: 0, py: 0, borderRadius: "2px 14px 14px 2px" }}
            color="ochre"
            onClick={() => {
              handlePrevNext(() => measure + 1);
            }}
          >
            <AddIcon />
          </Button>
        </ThemeProvider>
      </Box>
    </FormControl>
  );
}

export default PresentationMeasureControl;
