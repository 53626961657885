import React from "react";
import { getElementsInSection } from "../Common/Utils/ElementsAndSectionUtils.js";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import AddElementsNotification from "./AddElementsNotification.jsx";
import SectionHeaderButton from "../Common/SectionHeaderButton";
import StaffSectionElementExpanded from "./StaffSectionElementExpanded";
import styled from "styled-components";

const StaffContainer = styled.div`
  border-radius: 8px;
  display: grid;
  grid-template-columns: 12ch 4px auto;
  transition: margin-top ease-in 0.5s;
  margin-top: 0rem;
  min-width: 125ch;
  max-width: fit-content;
  font-family: "Courier New", Courier, monospace;
  font-size: inherit;
  letter-spacing: inherit;
  position: relative;
  top: 12px;
`;

const SectionContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isDragging'].includes(prop),
})`
  background: ${(props) => props.isDragging ? "rgba(255,255,255,0.75)"  : "transparent"};
  box-shadow: ${(props) => props.isDragging ? "2px 2px 3px rgba(0,0,0,0.25)"  : "none"};
  border-radius: 8px;
`;

const IndividualSectionList = styled.div.withConfig({
  shouldForwardProp: (prop) => !['rowIndex', 'isDraggingOver'].includes(prop),
})`
  width: 125ch;
  padding: ${(props) => (props.rowIndex === 0 ? "0px" : "4px 0px")};
  border-radius: 8px;
  background-color: ${(props) =>
    props.isDraggingOver ? "rgba(209, 234, 255, .65)" : "rgba(0,0,0,0)"};
  transition: background-color 1s ease-in-out;
`;

export default function IndividualSection(
  {
    handleExpandSection,
    page,
    section,
    staffElements,
    setStaffElements,
    dialogue,
    setDialogue,
    sectionExpanded,
    characterFocus,
    setCharacterFocus,
    handleShowSectionModal,
    index,
  },
) {
  function getElementOrder(elementOrderArray, page) {
    if (elementOrderArray.length === 0) {
      return elementsInSection;
    }
    const elementOrderArrayKeys = Object.keys(elementOrderArray).map((k) =>
      parseInt(k)
    );
    const index = elementOrderArrayKeys.reduce(
      (accum, curr) => (curr > accum && curr <= page) ? curr : accum,
      0,
    );

    if (index === 0) {
      return [];
    }
    return elementOrderArray[index];
  }
  const isSectionExpanded = sectionExpanded.includes(section.sectionName);
  const elementsInSection = getElementsInSection(page, section);
  const elementOrderArray = section.elementOrderArray === undefined
    ? {}
    : section.elementOrderArray;
  const elementOrder = getElementOrder(elementOrderArray, page);
  const elementDisplay = [
    ...elementOrder.filter((e) => elementsInSection.includes(e)),
    ...elementsInSection.filter((e) => !elementOrder.includes(e)),
  ];
 

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }
    if (destination.droppableId !== source.droppableId) {
      "Cannot drop in another section";
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    if (destination.droppableId === source.droppableId) {
      const newElementOrder = Array.from(
        elementDisplay.toSpliced(source.index, 1).toSpliced(
          destination.index,
          0,
          draggableId,
        ),
      );
      let nextElementOrderArray = { ...elementOrderArray };
      nextElementOrderArray[page] = newElementOrder;
      const nextSection = {
        ...section,
        elementOrderArray: nextElementOrderArray,
      };
      setStaffElements({
        ...staffElements,
        sections: staffElements.sections.map((s) =>
          s.sectionName === section.sectionName ? nextSection : s
        ),
      });
    }
  };
  return (
    <Draggable draggableId={section.sectionName} index={index}>
      {(provided,snapshot) => (
        <SectionContainer
          {...provided.draggableProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
        >
          <StaffContainer>
            <SectionHeaderButton
              dragHandleProps={provided.dragHandleProps}
              sectionName={section.sectionName}
              onClickAction={() => handleExpandSection(section.sectionName)}
              isSectionExpanded={isSectionExpanded}
            />
          </StaffContainer>
          {section.elements.length > 0
            ? (
              <DragDropContext
                onDragEnd={onDragEnd}
              >
                <Droppable droppableId={section.sectionName}>
                  {(provided, snapshot) => (
                    <IndividualSectionList
                      rowIndex={index}
                      ref={provided.innerRef}
                      isDraggingOver={snapshot.isDraggingOver}
                      {...provided.droppableProps}
                    >
                      {elementDisplay
                        .map((element, index) => {
                          if (index === 0 || isSectionExpanded) {
                            return (
                              <StaffSectionElementExpanded
                                key={element}
                                staffElements={staffElements}
                                setStaffElements={setStaffElements}
                                element={element}
                                highlighted={false}
                                rowStyle={"staff-row-presentation"}
                                dialogue={dialogue}
                                setDialogue={setDialogue}
                                characterFocus={characterFocus}
                                setCharacterFocus={setCharacterFocus}
                                section={section}
                                page={page}
                                emptySpacer={index ===
                                  elementsInSection.length - 1}
                                index={index}
                                isSectionExpanded={isSectionExpanded}
                              />
                            );
                          }
                        })}
                      {provided.placeholder}
                    </IndividualSectionList>
                  )}
                </Droppable>
              </DragDropContext>
            )
            : (
              <div className="staff-container">
                <AddElementsNotification
                  section={section}
                  handleShowSectionModal={handleShowSectionModal}
                />
              </div>
            )}
        </SectionContainer>
      )}
    </Draggable>
  );
}
