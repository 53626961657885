import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Divider, IconButton, Stack, Typography } from "@mui/joy";
import ConfirmModal from "../Common/ConfirmModal";
export default function EditTransitionToolbar({
  stageDirectionAction,
  setStageDirectionAction,
  stageDirections,
  setStageDirections,
  showStageDirections,
  setShowStageDirections,
  handleCloseStageDirections,
}) {
  const helperText = {
    empty:
      "Choose whether FIRST transition is entering or exiting, elements are present by default",
    notEmpty: stageDirectionAction === "Delete"
      ? "Click on shaded region to remove Transition"
      : "Click to staffline to add transition or toggle Delete to remove transitions",
  };
  const clearTransitions = () => {
    setStageDirections([]);
    setStageDirectionAction(null);
    setShowConfirmModal(false);
  };

  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const confirmModalButtons = [
    { label: "No", color: "primary", action: () => setShowConfirmModal(false) },
    {
      label: "Yes",
      color: "danger",
      action: clearTransitions,
    },
  ];
  // BETTER IDEA:
  // A banner / button across the top that toggles between Add and Remove Transitions
  // underneath explains what the function does and provides some extra buttons if necessary
  return (
    <>
      {showStageDirections && (
        <div
          style={{
            gridColumn: "1 /-1",
            gridRow: "1",
            marginLeft: "13ch",
            justifySelf: "center",
            maxWidth: "calc(50ch - 2px)",
            marginBottom: "4px",
            borderRadius: "1rem",
            padding: ".125rem 0rem",
          }}
        >
          <Stack
            direction="column"
            spacing={0}
            sx={{
              background: "#f2f2f2",
              width: "auto",
              padding: " 0.5rem 2.5rem",
              borderRadius: "0.5rem",
            }}
          >
            <Box>
              <Box display="flex" justifyContent="space-between">
                <Typography
                  level="h4"
                  sx={{
                    flexGrow: "0",
                    py: "0",
                    my: "0",
                    textAlign: "center",
                    fontWeight: 700,
                    gridColumn: "1 /-1",
                    transition: "opacity ease-in 0.5s",
                  }}
                >
                  {stageDirectionAction === "Delete"
                    ? "Remove Transitions"
                    : "Add Transitions"}
                </Typography>
                <IconButton
                  onClick={() => handleCloseStageDirections()}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Divider />
              <Typography
                sx={{
                  mb: "1rem",
                  fontSize: "1rem",
                  letterSpacing: "1px",
                  gridColumn: "1 /-1",
                }}
              >
                {stageDirections.length === 0 && helperText.empty}
                {stageDirections.length > 0 && helperText.notEmpty}
              </Typography>
              <Box display="flex" gap="1rem" justifyContent="space-around">
                {[{
                  label: "Enter",
                  color: "primary",
                  disabled: stageDirections.length > 0,
                }, {
                  label: "Exit",
                  color: "primary",
                  disabled: stageDirections.length > 0,
                }, {
                  label: "Delete",
                  color: "danger",
                  disabled: stageDirections.length === 0,
                }]
                  .map((button) => {
                    const isDisplayNone = (stageDirections.length > 0 &&
                      (button.label === "Enter" || button.label === "Exit")) ||
                      (stageDirections.length === 0 &
                        button.label === "Delete");
                    // console.log(
                    //   `${button.label} and sd.len ${stageDirections.length} and display ${isDisplayNone} `,
                    // );

                    return (
                      isDisplayNone ? (null) : (
                        <Button
                          key={button.label}
                          color={button.color}
                          disabled={button.disabled}
                          display={isDisplayNone ? "none" : "inline-flex"}
                          variant={stageDirectionAction === button.label
                            ? "solid"
                            : "outlined"}
                          onClick={() =>
                            setStageDirectionAction(
                              stageDirectionAction === button.label
                                ? null
                                : button.label,
                            )}
                          size={"sm"}
                        >
                          {button.label}
                        </Button>
                      )
                    );
                  })}
                {stageDirections.length > 0
                  ? (
                    <Button
                      size={"sm"}
                      onClick={() => {
                        setShowConfirmModal(true);
                      }}
                    >
                      Remove ALL Transitions
                    </Button>
                  )
                  : (null)}
              </Box>
            </Box>
          </Stack>
        </div>
      )}
      <ConfirmModal
        setOpen={setShowConfirmModal}
        open={showConfirmModal}
        modalTitle={"Clear ALL Transitions for this element?"}
        modalBodyText={"This will remove all Transitions for this element in this section. You can use the delete button to remove individual Transitions."}
        modalButtons={confirmModalButtons}
      />
    </>
  );
}
