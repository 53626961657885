import React from "react";
import { Button, Typography } from "@mui/joy";
export default function AddElementsNotification({handleShowSectionModal, section}) {
  return (
    <div
      style={{
        display: "grid",
        gridColumn: "2 / 5",
        padding: "0.5rem 0",
        borderRadius: "8px",
        alignContent: "center",
        justifyContent: "center",
        backgroundColor: "rgb(236, 236, 236)",
      }}
    >
      <Typography level="h4">
        Section contains no elements
      </Typography>
      <Button
        onClick={() => handleShowSectionModal("2", section.sectionName)}
      >
        Click to add elements
      </Button>
    </div>
  );
}
